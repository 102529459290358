import FranchiseCreator from "./pages/FranchiseCreatorPage/FranchiseCreator";
import IndustryCreator from "./pages/AdminPages/IndustryCreator";
import NewsCreator from "./pages/AdminPages/NewsCreator";
import TypeCreator from "./pages/AdminPages/TypeCreator";
import ReleaseCreator from "./pages/AdminPages/ReleaseCreator";
import Admin from "./pages/AdminPages/Admin";
import Auth from "./pages/AuthPage/Auth";
import Contacts from "./pages/ContactsPage/Contacts";
import Favorites from "./pages/FavoritesPage/Favorites";
import FranchisePage from "./pages/FranchisePage/FranchisePage";
import Home from "./pages/HomePage/Home";
import News from "./pages/NewsPage/News";
import NewsPage from "./pages/NewsItemPage/NewsItem";
import Profile from "./pages/ProfilePages/Profile";
import PublicProfile from "./pages/ProfilePages/PublicProfile";
import FranchisesCatalog from "./pages/FranchisesCatalogPage/FranchisesCatalog";
import Questionnaire from "./pages/QuestionnairePage/Questionnaire";
import PendingFranchises from "./pages/AdminPages/PendingFranchises";
import PrivacyPolicy from "./pages/PrivacyPolicyPage/PrivacyPolicy";
import NothingFound from "./pages/NothingFoundPage/NothingFound";
import SubscribersManagment from "./pages/AdminPages/SubscribersManagment";
import FranchiseStatusManager from "./pages/AdminPages/FranchiseStatusManager";
import CatalogOrderManagement from "./pages/AdminPages/CatalogOrderManagement";

import {
      RELEASE_CREATION_ROUTE,NEWS_PAGE_ROUTE,PUBLIC_PROFILE_ROUTE,FORGOT_PASSWORD_ROUTE, FRANCHISE_CREATION_ROUTE, NEWS_CREATION_ROUTE, ADMIN_ROUTE,
      CONTACTS_ROUTE, FAVORITES_ROUTE, FRANCHISE_PAGE_ROUTE, HOME_ROUTE, LOGIN_ROUTE, NEWS_ROUTE, PROFILE_ROUTE, REGISTRATION_ROUTE, QUESTIONARY_ROUTE, SUBSCRIBERS_LIST_ROUTE,
      INDUSTRY_CREATION_ROUTE, TYPE_CREATION_ROUTE, FRANCHISE_EDIT_ROUTE, FRANCHISE_CATALOG_ROUTE, PENDING_FRANCHISES_ROUTE, NOTHING_FOUND_ROUTE, PRIVACY_POLICY_ROUTE,
      FRANCHISE_STATUS_MANAGER_ROUTE, FRANCHISE_RECOVERY_ROUTE,CATALOG_ORDER_MANAGER_ROUTE
} from "./utilits/consts";
import RecoveryFranchises from "./pages/AdminPages/RecoveryFranchises";


export const adminRoutes = [
      {
            path: ADMIN_ROUTE,
            Component: Admin
      },
      {
            path: INDUSTRY_CREATION_ROUTE,
            Component: IndustryCreator
      },
      {
            path: RELEASE_CREATION_ROUTE,
            Component: ReleaseCreator
      },
      {
            path: TYPE_CREATION_ROUTE,
            Component: TypeCreator
      },
      {
            path: NEWS_CREATION_ROUTE,
            Component: NewsCreator
      },
      {
            path: PENDING_FRANCHISES_ROUTE,
            Component: PendingFranchises
      },
      {
            path: SUBSCRIBERS_LIST_ROUTE,
            Component: SubscribersManagment
      },
      {
            path: FRANCHISE_STATUS_MANAGER_ROUTE,
            Component: FranchiseStatusManager
      },
      {
            path: FRANCHISE_RECOVERY_ROUTE,
            Component: RecoveryFranchises
      },
      {
            path:CATALOG_ORDER_MANAGER_ROUTE,
            Component: CatalogOrderManagement
      }
]
export const authRoutes = [
      {
            path: PROFILE_ROUTE,
            Component: Profile
      },
      {
            path: FAVORITES_ROUTE,
            Component: Favorites
      },
      {
            path: FRANCHISE_CREATION_ROUTE,
            Component: FranchiseCreator
      },
      {
            path: FRANCHISE_EDIT_ROUTE +'/:id',
            Component: FranchiseCreator
      },
]
export const publicRoutes = [
      {
            path: REGISTRATION_ROUTE,
            Component: Auth
      },
      {
            path: NEWS_PAGE_ROUTE +'/:id',
            Component: NewsPage
      },
      {
            path: PUBLIC_PROFILE_ROUTE +'/:id',
            Component: PublicProfile
      },
      {
            path: LOGIN_ROUTE,
            Component: Auth
      },
      {
            path: FORGOT_PASSWORD_ROUTE,
            Component: Auth
      },
      {
            path: NEWS_ROUTE,
            Component: News
      },
      {
            path: HOME_ROUTE,
            Component: Home
      },
      {
            path: CONTACTS_ROUTE,
            Component: Contacts
      },
      {
           path: FRANCHISE_PAGE_ROUTE +'/:id',
           Component: FranchisePage
      },
      {
            path: FRANCHISE_CATALOG_ROUTE,
            Component: FranchisesCatalog
      },
      {
            path: QUESTIONARY_ROUTE,
            Component: Questionnaire
      },
      {
            path: NOTHING_FOUND_ROUTE,
            Component: NothingFound
      },
      {
            path: PRIVACY_POLICY_ROUTE,
            Component: PrivacyPolicy
      }
]