import { runInAction, toJS } from "mobx";
import $api from "../http/index";
import { IFranchise } from "../models/IFranchise";
import { IDescriptionBlock } from "../models/IDescriptionBlock";

export default class FranchiseService {
    static async createFranchise(
        form: {
            name: string;
            royalty: string;
            advertisingFee: string;
            investments: string;
            ownPoints: string;
            franchisePoints: string;
            paybackTime: string;
            industryId: string;
            typeOfActivityId: string;
            brand: string;
            faceImage: File;
            companyStartYear: string;
            franchiseStartYear: string;
            descriptionBlocks: IDescriptionBlock[];
        }
    ) {
        const formData = new FormData();

        formData.append('name', form.name);
        formData.append('royalty', form.royalty);
        formData.append('advertisingFee', form.advertisingFee);
        formData.append('investments', form.investments);
        formData.append('ownPoints', form.ownPoints);
        formData.append('franchisePoints', form.franchisePoints);
        formData.append('paybackTime', form.paybackTime);
        formData.append('industryId', form.industryId);
        formData.append('typeOfActivityId', form.typeOfActivityId);
        formData.append('brand', form.brand);
        formData.append('faceImage', form.faceImage);
        formData.append('companyStartYear',form.companyStartYear);
        formData.append('franchiseStartYear',form.franchiseStartYear);

        form.descriptionBlocks.forEach((block, index) => {
            formData.append(`descriptionBlocks[${index}].description`, block.description);

            block.imageGallery?.forEach((image, imageIndex) => {
                formData.append(
                    `descriptionBlocks[${index}].imageGallery[${imageIndex}]`,
                    image as File
                );
            });
        });

        try {
            const response = await $api.post('/franchises/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response;
        } catch (error) {
            console.error("Ошибка при создании франшизы:", error);
            throw error; 
        }
    }

    static async updateFranchiseOrder(selectedFranchises: number[]) {
        try {
            const { data } = await $api.patch(`/franchises/set-order`, { selectedFranchises });
            return data;
        } catch (e) {
            throw e;
        }
    }
    
    static async updateFranchise (
        id: string,
        form: {
            name: string,
            royalty: string,
            advertisingFee: string,
            investments: string,
            ownPoints: string,
            franchisePoints: string,
            paybackTime: string,
            industryId: string,
            typeOfActivityId: string,
            brand: string,
            faceImage: File,
            companyStartYear: string;
            franchiseStartYear: string;
            descriptionBlocks: IDescriptionBlock[];
        }
    ) {
        const formData = new FormData();
      
        formData.append('name', form.name);
        formData.append('royalty', form.royalty);
        formData.append('advertisingFee', form.advertisingFee);
        formData.append('investments', form.investments);
        formData.append('ownPoints', form.ownPoints);
        formData.append('franchisePoints', form.franchisePoints);
        formData.append('paybackTime', form.paybackTime);
        formData.append('industryId', form.industryId);
        formData.append('typeOfActivityId', form.typeOfActivityId);
        formData.append('brand', form.brand);
        formData.append('faceImage', form.faceImage);
        formData.append('companyStartYear',form.companyStartYear);
        formData.append('franchiseStartYear',form.franchiseStartYear);

        form.descriptionBlocks.forEach((block, index) => {
            formData.append(`descriptionBlocks[${index}].description`, block.description);

            block.imageGallery?.forEach((image, imageIndex) => {
                formData.append(
                    `descriptionBlocks[${index}].imageGallery[${imageIndex}]`,
                    image as File
                );
            });
        });

        try {
        const response = await $api.put(`/franchises/update/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    } catch (error) {
        console.error("Ошибка при обновлении франшизы:", error);
        throw error; 
    }
    }

    static async fetchPage(
        industryIds: number[] | string | undefined,
        typeOfActivityIds: number[] | string | undefined,
        page: number,
        limit: number | undefined,
        budget?: string,
        sort?: string,
    ): Promise<{ franchises: IFranchise[]; totalCount: number }> {
        try {
            const params: any = {
                budget,
                sort,
            };
    
            params.page = page;
            params.limit = limit;
    
            if (industryIds) {
                params.industryIds = Array.isArray(industryIds) ? industryIds.join(',') : industryIds;
            }
    
            if (typeOfActivityIds) {
                params.typeOfActivityIds = Array.isArray(typeOfActivityIds) ? typeOfActivityIds.join(',') : typeOfActivityIds;
            }
    
            const response = await $api.get('/franchises/get-page', { params });
            if (response && response.data) {
                return {
                    franchises: response.data.franchises,
                    totalCount: response.data.totalCount,
                };
            } else {
                throw new Error('Invalid response from API');
            }
        } catch (error) {
            console.error('Error fetching franchises:', error);
            throw error;
        }
    }

    static async fetchOneFranchise(id: number) {
        try {
            const { data } = await $api.get(`/franchises/get-one/${id}`);
            return data;
        }
        catch(e) {
            throw e;
        }
    }

    static async fetchAllFranchises(): Promise<IFranchise[]> {
        try {
            const { data } = await $api.get(`/franchises/get-all`);
            return data;
        }
        catch(e) {
            throw e;
        }
    }

    static async fetchFranchisesByIndustry(id:number, industryId:number, limit: number) {
        try {
            const response = await $api.get('/franchises/get-by-industry/', { params:{id,industryId,limit}});
            return response.data;
        }
        catch(e) {
            throw e;
        }
    }

    static async fetchFranchiseByReleaseId(id: number) {
        try {
            const { data } = await $api.get(`/franchises/get-by-release/${id}`);
            return data;
        }
        catch(e) {
            throw e;
        }
    }

    static async fetchRecommendedFranchises() {
        try {
            const response = await $api.get('/franchises/get-recommended');
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    
    static async setRecommendedFranchises(newRecommendedFranchises: number[]) {
        try {
            const response = await $api.post('/franchises/set-recommended', {
                recommendedFranchises: newRecommendedFranchises
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    
    static async deleteOneFranchise(id: number) {
        try {
            const { data } = await $api.delete(`/franchises/remove/${id}`);
            return data
        }
        catch(e) {
            throw e;
        }
    }
}

const appendFranchiseData = (
    formData: FormData,
    name: string,
    royalty: number,
    advertisingFee: number,
    investments: number,
    ownPoints: number,
    franchisePoints: number,
    paybackTime: number,
    industryId: number,
    typeOfActivityId: number,
    brand: string,
    description: string,
    faceImage: File | null,
    
) => {
    formData.append('name', name);
    formData.append('royalty', royalty.toString());
    formData.append('advertisingFee', advertisingFee.toString());
    formData.append('investments', investments.toString());
    formData.append('ownPoints', ownPoints.toString());
    formData.append('franchisePoints', franchisePoints.toString());
    formData.append('paybackTime', paybackTime.toString());
    formData.append('industryId', industryId.toString());
    formData.append('typeOfActivityId', typeOfActivityId.toString());
    formData.append('brand', brand);
    formData.append('description', description);
    
    if (faceImage) {
        formData.append('faceImage', faceImage);
    }
    
};