import { useContext, useEffect } from 'react';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import { IFranchise } from '../../models/IFranchise';

import LoadingDots from '../../UI/loadingDots';
import NothingFound from '../NothingFoundPage/NothingFound';

import classes from './AdminPageForms.module.scss';
import UnusedFranchiseCard from '../../UI/cards/UnusedFranchiseCard';


const RecoveryFranchises = observer(() => {
     const { franchiseApprovalStore  } = useContext(Context);

     useEffect(() => {
          const fetchData = async () => {
               await franchiseApprovalStore.fetchFranchisesWithStatus();
          };
          fetchData();
     }, [franchiseApprovalStore]);

     if (franchiseApprovalStore.isLoading) {
          return (
               <LoadingDots />
          )
     }

     return (
          <div className={classes.adminDashboard__container}>
               <div className={classes.adminDashboard__content}>
                    <h1>Франшизы на рассмотрении</h1>
                    {franchiseApprovalStore.franchisesWithStatus.length > 0 ? (
                         franchiseApprovalStore.franchisesWithStatus.map((franchise: IFranchise) => (
                              <UnusedFranchiseCard 
                                   key={franchise.id}
                                   franchise={franchise}
                              />
                         ))
                    ) : (
                         <NothingFound text='На данный момент не франшиз на одобрение'/>
                    )}
               </div>
          </div>
     );
});

export default RecoveryFranchises;
