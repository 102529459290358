export const LOGIN_ROUTE = '/login'
export const FORGOT_PASSWORD_ROUTE = '/recovery'
export const REGISTRATION_ROUTE = '/registration'
export const RECOVERY_PASSWORD_ROUTE = '/recovery-password'

export const FRANCHISE_PAGE_ROUTE = '/franchise'
export const FRANCHISE_CREATION_ROUTE = '/create-franchise'
export const FRANCHISE_EDIT_ROUTE = '/edit-franchise'

export const ADMIN_ROUTE = '/admin'
export const RELEASE_CREATION_ROUTE = '/admin/create-release'
export const NEWS_CREATION_ROUTE = '/admin/create-news'
export const PENDING_FRANCHISES_ROUTE = '/admin/pending-franchises'
export const INDUSTRY_CREATION_ROUTE = '/admin/create-industry'
export const TYPE_CREATION_ROUTE = '/admin/create-type'
export const FRANCHISE_STATUS_MANAGER_ROUTE = '/admin/franchise-status-manager'
export const FRANCHISE_RECOVERY_ROUTE = '/admin/franchise-recovery'
export const CATALOG_ORDER_MANAGER_ROUTE = '/admin/catalog-order-manager'

export const PROFILE_ROUTE = '/profile'
export const PUBLIC_PROFILE_ROUTE = '/public-profile'
export const SUBSCRIBERS_LIST_ROUTE = '/subscribers-list'
export const FAVORITES_ROUTE = '/favorites'
export const CREATE_ROUTE = '/create'

export const FRANCHISE_CATALOG_ROUTE = '/catalog'
export const QUESTIONARY_ROUTE = '/questionnaire'

export const NEWS_ROUTE = '/news'
export const NEWS_PAGE_ROUTE = '/news/newspages'

export const HOME_ROUTE = '/home'
export const CONTACTS_ROUTE = '/contacts'
export const NOTHING_FOUND_ROUTE = '/nothing-found'
export const PRIVACY_POLICY_ROUTE = '/privacy-policy'