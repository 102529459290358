import axios from 'axios';
import { AuthResponse } from '../models/response/AuthResponse';
import {LOGIN_ROUTE} from "../utilits/consts"
export const API_URL = process.env.REACT_APP_API_URL
export const STATIC_URL = process.env.REACT_APP_STATIC_URL

const $api = axios.create({
      withCredentials: true,
      baseURL: API_URL
})
  
$api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
      return config;
})

let isRefreshing = false;
let failedRequestsQueue: Array<() => void> = [];

$api.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response?.status === 401 && !originalRequest._isRetry) {
      if (isRefreshing) {
        // Добавляем запрос в очередь
        return new Promise((resolve) => {
          failedRequestsQueue.push(() => resolve($api.request(originalRequest)));
        });
      }

      originalRequest._isRetry = true;
      isRefreshing = true;

      try {
        const response = await axios.get<AuthResponse>(`${API_URL}/users/refresh`, {
          withCredentials: true
        });
        localStorage.setItem('accessToken', response.data.accessToken);
        
        // Повторяем все запросы из очереди
        failedRequestsQueue.forEach(cb => cb());
        failedRequestsQueue = [];
        
        return $api.request(originalRequest);
      } catch (refreshError) {
            localStorage.removeItem('accessToken');
            failedRequestsQueue = [];
            window.location.href = `${window.location.origin}${LOGIN_ROUTE}`;
           return Promise.reject(refreshError);
        // Редирект на логин
      } finally {
        isRefreshing = false;
      }
    }
    
    throw error;
  }
);

export default $api;