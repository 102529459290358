import FranchiseCard from "../../../../UI/cards/FranchiseCard";
import RecommendedFranchiseCard from "../../../../UI/cards/RecommendedFranchiseCard";
import SkeletonRecommendedCard from "../../../../UI/skeletons/SkeletonRecommendedCard";
import Skeleton from "../../../../UI/skeletons/Skeleton";

import { PUBLIC_PROFILE_ROUTE } from "../../../../utilits/consts";

import { useContext, useEffect } from "react";
import { Context } from "../../../../index";
import { STATIC_URL } from "../../../../http";
import { observer } from "mobx-react-lite";
import ScrollToTopNavLink from "../../../../UI/ScrollToTopNavLink";

import classes from './RecommendedFranchises.module.scss'
import SkeletonsFranchiseCard from "../../../../UI/skeletons/SkeletonFranchiseCard";

import defaultUserImage from '../../../../assets/defaultUser.png'

const RecommendedFranchises = () => {
     const { franchiseStore, releaseStore } = useContext(Context);
     
     useEffect(() => {
          releaseStore.fetchSpecialFranchise();
          franchiseStore.fetchRecommendedFranchises();
     }, [])
     
     const specFranchOwnerInfo = releaseStore.specialFranchise?.ownerInfo;
     const ownerImage = specFranchOwnerInfo ? `${STATIC_URL}/users/${specFranchOwnerInfo.img}` : defaultUserImage;

     return (
          <div className={classes.recommendedFranchises__container}>
               <div className={classes.recommendedFranchises__content}>
                    <h1>Наши рекомендации</h1>
                    <div className={classes.recommendedFranchises__wrapper}>
                         <div className={classes.specialFranchise__container}>
                              {releaseStore.specialFranchise ? (
                                   <>
                                        <div className={classes.specialFranchise__card}>
                                             <FranchiseCard 
                                                  franchise={releaseStore.specialFranchise}
                                                  displayOwner={false}
                                                  displayName={false}
                                                  isActive
                                             />
                                             <div className={classes.specialFranchise__infoBody}>
                                                  <ScrollToTopNavLink to={`${PUBLIC_PROFILE_ROUTE}/${releaseStore.specialFranchise?.ownerInfo?.id}`} >
                                                       <img 
                                                            className={classes.specialFranchise__ownerImage}
                                                            src={ownerImage}
                                                            alt={specFranchOwnerInfo?.firstName}
                                                            onError={(e) => (e.currentTarget.src = defaultUserImage)}
                                                       />
                                                  </ScrollToTopNavLink>
                                                  <div className={classes.specialFranchise__owner}>
                                                       <p>{releaseStore.specialFranchise?.name}</p>
                                                       <span>
                                                            {releaseStore.specialFranchise.ownerInfo?.firstName} {releaseStore.specialFranchise.ownerInfo?.lastName}
                                                       </span>
                                                  </div>
                                             </div>
                                        </div>
                                   </>
                              ) : (
                                   <>
                                        <div className={classes.specialFranchise__card}>
                                             <SkeletonsFranchiseCard/>
                                             <div className={classes.specialFranchise__infoBody}>
                                                  <Skeleton className={classes.specialFranchise__ownerImage}/>
                                                  <Skeleton width={48} height={48} borderRadius={"50%"} contrast="dark"/>
                                                  <div className={classes.specialFranchise__owner}>
                                                       <Skeleton width={220} height={"1.25rem"} borderRadius={4}/>
                                                       <Skeleton width={100} height={"0.875rem"} borderRadius={4}/>
                                                  </div>
                                             </div>
                                        </div>
                                   </>
                              )}
                         </div>
                         <div className={classes.recommendedFranchises__gridContainer}>
                              {franchiseStore.recommendedFranchises.length !== 0 ? (
                                   <>
                                        {franchiseStore.recommendedFranchises.map((franchise, _) => (
                                             <RecommendedFranchiseCard
                                                  key={franchise.id}
                                                  franchise={franchise}
                                             />
                                        ))}
                                   </>
                              ) : (
                                   <>
                                        {Array.from({ length: 6}).map((_, index) => (
                                             <SkeletonRecommendedCard key={index}/>
                                        ))}
                                   </>
                              )}
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default observer(RecommendedFranchises);