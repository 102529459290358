import { useState, useEffect, useContext, useRef } from "react";
import { ADMIN_ROUTE, CONTACTS_ROUTE, FAVORITES_ROUTE, FRANCHISE_CATALOG_ROUTE, HOME_ROUTE, LOGIN_ROUTE, NEWS_ROUTE, PROFILE_ROUTE, REGISTRATION_ROUTE } from "../../utilits/consts";
import DesktopLogo from '../../assets/logo/logo.svg';
import MobileLogo from '../../assets/logo/mobile_logo.svg';
import UserIcon from '../../assets/icons/user.svg';
import SearchInput from "../../UI/searches/SearchInput";
import ScrollToTopNavLink from "../../UI/ScrollToTopNavLink";
import { useLocation } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import classes from './Header.module.scss';
import CustomButton from "../../UI/buttons/CustomButton";
import QuestionnaireButton from "../../UI/buttons/QuestionnaireButton";

const Header = () => {
    const { userStore } = useContext(Context);
    const location = useLocation();
    const [isVisible, setIsVisible] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { isMobile, isTablet } = useResponsive();
    const menuRef = useRef<HTMLDivElement>(null);

    let lastScrollTop = 0;
    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        if (!isMobile) {
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);

    useEffect(() => {
        if (userStore.isAuth) {
            userStore.checkAdmin();
        }
    }, [userStore.isAuth]);

    return (
        <header className={isVisible ? classes.visible : classes.hidden}>
            <div className={classes.header__body}>
                <div className={classes.header__start} ref={menuRef}>
                    {isMobile && (
                        <div className={classes.header__burger}>
                            <button
                                className={`${classes.burger__button} ${isMenuOpen && classes.open}`}
                                onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            </button>
                            <div className={`${classes.burger__menu} ${isMenuOpen ? classes.open : classes.close}`}>
                                <nav className={classes.burger__navigation}>
                                    <ScrollToTopNavLink
                                        to={FRANCHISE_CATALOG_ROUTE}
                                        className={classes.nav__item}
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        Каталог франшиз
                                    </ScrollToTopNavLink>

                                    {userStore.isAuth ? (
                                    <ScrollToTopNavLink
                                            to={FAVORITES_ROUTE}
                                            className={classes.nav__item}
                                            onClick={() => setIsMenuOpen(false)}
                                        >
                                    Избранное
                                    </ScrollToTopNavLink>
                                    ) : (
                                        <ScrollToTopNavLink
                                            to={REGISTRATION_ROUTE}
                                            className={classes.nav__item}
                                            onClick={() => setIsMenuOpen(false)}
                                        >
                                            Регистрация
                                        </ScrollToTopNavLink>
                                    )}

                                    <ScrollToTopNavLink
                                        to={NEWS_ROUTE}
                                        className={classes.nav__item}
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        Новости
                                    </ScrollToTopNavLink>

                                    <ScrollToTopNavLink
                                        to={CONTACTS_ROUTE}
                                        className={classes.nav__item}
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        Контакты
                                    </ScrollToTopNavLink>

                                    <li className={classes.burger__questionnaireButton}>
                                        <QuestionnaireButton
                                            onClick={() => setIsMenuOpen(false)}
                                        />
                                    </li>
                                </nav>
                            </div>
                        </div>
                    )}
                    <ScrollToTopNavLink to={HOME_ROUTE} className={classes.header__logo}>
                        <img src={isTablet || isMobile ? MobileLogo : DesktopLogo} alt="Francheese Logo" />
                    </ScrollToTopNavLink>

                    <nav className={classes.navigation}>
                        <ScrollToTopNavLink
                            to={HOME_ROUTE}
                            className={`${classes.nav__item} ${location.pathname === HOME_ROUTE ? classes.active : ''}`}
                        >
                            Главная
                        </ScrollToTopNavLink>

                        {!isMobile && (
                            <>
                                <ScrollToTopNavLink
                                    to={FRANCHISE_CATALOG_ROUTE}
                                    className={`${classes.nav__item} ${location.pathname === FRANCHISE_CATALOG_ROUTE ? classes.active : ''}`}
                                >
                                    Каталог франшиз
                                </ScrollToTopNavLink>

                                <ScrollToTopNavLink
                                    to={NEWS_ROUTE}
                                    className={`${classes.nav__item} ${location.pathname === NEWS_ROUTE ? classes.active : ''}`}
                                >
                                    Новости
                                </ScrollToTopNavLink>
                            </>
                        )}
                    </nav>
                </div>
                <div className={classes.header__end}>
                    <SearchInput isVisible={isVisible && !isMenuOpen} />
                    <nav className={classes.navigation}>
                        {userStore.isAuth ? (
                            <>
                                {!isMobile && (
                                    <ScrollToTopNavLink to={FAVORITES_ROUTE}>
                                        <CustomButton bodyType="empty" padding="1.25rem 1.375rem">
                                            Избранное
                                        </CustomButton>
                                    </ScrollToTopNavLink>
                                )}

                                <ScrollToTopNavLink to={PROFILE_ROUTE}>
                                    {!isMobile ? (
                                        <CustomButton padding="1.25rem 2.5rem">
                                            Профиль
                                        </CustomButton>
                                    ) : (
                                        <img src={UserIcon} alt="User Icon" />
                                    )}
                                </ScrollToTopNavLink>
                            </>
                        ) : (
                            <>
                                <ScrollToTopNavLink to={LOGIN_ROUTE}>
                                    <CustomButton>
                                        Вход
                                    </CustomButton>
                                </ScrollToTopNavLink>
                                {!isMobile && (
                                    <ScrollToTopNavLink to={REGISTRATION_ROUTE}>
                                        <CustomButton bodyType={!isMobile ? "empty" : "filled"}>
                                            Регистрация
                                        </CustomButton>
                                    </ScrollToTopNavLink>
                                )}

                            </>
                        )}
                    </nav>
                </div>
                {userStore.isAdmin && (
                    <ScrollToTopNavLink
                        to={ADMIN_ROUTE}
                        className={`${classes.adminPage__link} ${location.pathname === ADMIN_ROUTE ? classes.active : ''}`}
                    >
                        Админ
                    </ScrollToTopNavLink>
                )}
            </div>
        </header>
    );
};

export default observer(Header);
