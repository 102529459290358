
import InteractiveCards from "../../../../UI/cards/InteractiveCards";
import ScrollToTopNavLink from "../../../../UI/ScrollToTopNavLink";
import CustomButton from "../../../../UI/buttons/CustomButton";

import { FRANCHISE_CATALOG_ROUTE } from "../../../../utilits/consts";

import { observer } from "mobx-react-lite";

import DotsImg from '../../../../assets/UI/dots.svg';
import classes from "./WelcomeSection.module.scss";
import { Helmet } from "react-helmet";
import useResponsive from "../../../../hooks/useResponsive";
import InteractiveCardsSwiper from "../../../../UI/swipers/InteractiveCardsSwiper";
import { useContext, useEffect } from "react";
import { Context } from "../../../../index";

const WelcomeSection = () => {
    const { isMobile } = useResponsive();
    const { releaseStore } = useContext(Context);

    useEffect(() => {
        releaseStore.fetchThreeInteractiveCards();
    }, [])
    
    return (
        <>
            <Helmet>
                <title>Станьте частью успешной сети с нашим каталогом франшиз</title>
                <meta name="description" content="Ваш путеводитель в мире франшиз. Francheese.by поможет запустить бизнес по франшизе быстро, легко и успешно" />
                <meta name="keywords" content="франшиза, каталог франшиз, открыть бизнес, бизнес по франшизе, Francheese.by" />
            </Helmet>
            <div className={classes.welcome__container}>
                <div className={classes.welcome__content}>
                    <div className={classes.welcome__box}>
                        <div className={classes.welcome__text}>
                            <h1>Станьте частью успешной сети с нашим каталогом франшиз</h1>
                            <p>Francheese.by поможет запустить бизнес по франшизе быстро, легко и успешно</p>
                        </div>
                        <div className={classes.welcome__buttonWrapper}>
                            <ScrollToTopNavLink to={FRANCHISE_CATALOG_ROUTE}>
                                <CustomButton className={classes.welcome__button} isCheeseButton>
                                    Подобрать франшизу
                                </CustomButton>
                            </ScrollToTopNavLink>
                            <img className={classes.dots__image} src={DotsImg} alt="Точки" />
                        </div>
                    </div>
                    {!isMobile ? (
                        <div className={classes.interactiveCards__body}>
                            <InteractiveCards />
                        </div>
                    ) : (
                        <div className={classes.interactiveCardsSwiper__body}>
                            <InteractiveCardsSwiper />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default WelcomeSection;
