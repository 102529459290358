import { FC, ReactNode, useState } from "react";
import classes from './AuthorizationInput.module.scss';
import Hint from "../Hint";

interface AuthorizationInputProps {
    value: string;
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
    type: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    name: string;
    icon?: ReactNode;
    error?: boolean;
    title?: string;
    hintMessage?: string;
    hints?: string[];
    isPasswordField?: boolean;
    toggleIcon?: ReactNode;
    toggleIconActive?: ReactNode;
}

const AuthorizationInput: FC<AuthorizationInputProps> = ({
    value,
    onBlur,
    type,
    onChange,
    placeholder,
    name,
    icon,
    error,
    title,
    hintMessage,
    hints,
    isPasswordField,
    toggleIcon,
    toggleIconActive
}) => {
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [showHint, setShowHint] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!isPasswordVisible);
    };

    const handleFocus = () => {
        if (hintMessage && hints) {
            setShowHint(true);
        }
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setShowHint(false);
        onBlur(event);
    };

    return (
        <div className={classes.inputContainer}>
            <input
                type={isPasswordField && isPasswordVisible ? "text" : type}
                placeholder={placeholder}
                name={name}
                value={value}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={onChange}
                maxLength={30}
                className={`${classes.input} ${error ? classes.ErrorInput : ''}`}
                title={title}
                required
            />
            {icon && !isPasswordField ? (
                <div className={`${classes.inputIcon} ${error ? classes.ErrorIcon : ''}`}>
                    {icon}
                </div>
            ) : isPasswordField && (
                <div className={`${classes.inputIcon} ${error ? classes.ErrorIcon : ''}`} onClick={togglePasswordVisibility}>
                    {isPasswordVisible ? toggleIconActive : toggleIcon}
                </div>
            )}
            {showHint && hintMessage && (
                <Hint message={hintMessage} hints={hints || []} />
            )}
        </div>
    );
};

export default AuthorizationInput;
