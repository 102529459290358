import { useContext, useEffect, useState } from 'react';
import { Context } from '../../index';

import classes from './AdminPageForms.module.scss';
import CustomButton from '../../UI/buttons/CustomButton';
import MultiSelect from '../../UI/selectors/MultiSelect';
import { useNotification } from '../../hooks/useNotification';
import { IFranchise } from '../../models/IFranchise';

const CatalogOrderManagement = () => {
     const { franchiseStore } = useContext(Context);
     const [selectedFranchises, setSelectedFranchises] = useState<number[]>([]);
     const [options, setOptions] = useState<{ value: number, label: string }[]>([]);
     const { notify, showLoading, hideLoading } = useNotification();

     const handleFranchisesChange = (newSelectedFranchises: number[]) => {
          setSelectedFranchises(newSelectedFranchises);
     };

     const handleSelectedValuesChange = (newSelectedValues: number[]) => {
          setSelectedFranchises(newSelectedValues);
          handleFranchisesChange(newSelectedValues);
     };

    const fetchAllFranchises = async () => {
          try {
               await franchiseStore.fetchAllFranchises();
               const sortedFranchises = franchiseStore.franchises;
               const options = sortedFranchises.map((franchise: IFranchise) => ({
                    value: franchise.id,
                    label: franchise.name,
               }));
               setOptions(options);
               const selected = sortedFranchises.filter((franchise: IFranchise) => franchise.orderIndex !== null);
               setSelectedFranchises(selected.map((franchise: IFranchise) => franchise.id));
          } catch (error) {
               console.error("Не удалось получить все франшизы", error);
          }
    };

     useEffect(() => {
          const fetchData = async () => {
               await fetchAllFranchises();
          };
          fetchData();
     }, [franchiseStore]);

    const handleSubmit = async () => {
        try {
             showLoading('Устанавливаем новый порядок');
             await franchiseStore.updateFranchiseOrder(selectedFranchises);
             hideLoading();
             notify('Устанавлен новый порядок франшиз');
        } catch (error) {
             alert('Произошла ошибка при удалении франшизы');
        }
    };

    return (
        <div className={classes.adminDashboard__container}>
            <div className={classes.adminDashboard__content}>
                <div className={classes.adminToolsPanel}>
                    <div className={classes.adminToolBox}>
                         <h1>Установите порядок франшиз</h1>
                         <div className={classes.adminToolBox__controllers}>
                                   <MultiSelect
                                        options={options}
                                        selectedValues={selectedFranchises}
                                        onChange={handleSelectedValuesChange}
                                        enableSearch={true}
                                        enumerate={true}
                                   />
                         </div>
                         <CustomButton onClick={handleSubmit}>
                              Установить
                         </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CatalogOrderManagement;
