import { useState, useContext } from 'react';
import * as Yup from 'yup';

import { IIndustry } from '../../models/IIndustry';
import { Context } from '../..';
import { useNotification } from '../../hooks/useNotification';

import CustomButton from '../../UI/buttons/CustomButton';
import InputField from '../../UI/inputs/InputField/InputField';

import classes from './AdminPageForms.module.scss';

const IndustryCreator = () => {
    const { industryStore } = useContext(Context);

    const { notify, showLoading, hideLoading } = useNotification();
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [formData, setFormData] = useState<IIndustry>({
        id: 0,
        name: '',
    });

    const validationSchemaCreate = Yup.object({
        name: Yup.string().required('Это обязательное поле. Заполните его!').max(70, 'Максимум 70 символов'),
    });
    
    const validationSchemaDelete = Yup.object({
        id: Yup.number().required('Выберите индустрию для удаления'),
    });
    
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
        field: keyof IIndustry
    ) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [field]: value,
        });

        setErrors({ ...errors, [field]: false });
    };

    const handleSubmit = async () => {
        try {
            await validationSchemaCreate.validate(formData, { abortEarly: false });
            await industryStore.createIndustry(formData);
            await industryStore.fetchIndustries();
            notify(`Индустрия успешно создана!`);
            setFormData({ id: 0, name: '' });
            setErrors({});

        } catch (error) {
            handleValidationError(error);
        }
    };
    
    const handleDelete = async () => {
        try {
            await validationSchemaDelete.validate(formData, { abortEarly: false });
    
            showLoading("Удаление индустрии");
            await industryStore.deleteIndustry(formData.id);
            await industryStore.fetchIndustries();

            hideLoading();
            notify(`Индустрия успешно удалена!`);

            setFormData({ id: 0, name: '' });
            setErrors({});
        } catch (error) {
            handleValidationError(error);
        }
    };

    const handleValidationError = (error: any) => {
        if (error instanceof Yup.ValidationError) {
            const newErrors: { [key: string]: boolean } = {};
            error.inner.forEach((err) => {
                if (err.path) {
                    newErrors[err.path] = true;
                }
            });
            setErrors(newErrors);
        }
        notify(`Не удалось выполнить действие!, ${error}`);
        console.error("Ошибка:", error);
    };
    

    return (
        <div className={classes.adminDashboard__container}>
            <div className={classes.adminDashboard__content}>
                <div className={classes.adminToolsPanel}>
                    <div className={classes.adminToolBox}>
                        <h1>Создание отрасли</h1>
                        <div className={classes.adminToolBox__controllers}>
                            <InputField
                                as="input"
                                type="text"
                                maxLength={70}
                                placeholder="Название новой отрасли"
                                value={formData.name}
                                onChange={(e) => handleInputChange(e, 'name')}
                                error={errors.name}
                                errorMessage="Это обязательное поле. Заполните его!"
                            />
                        </div>
                        <CustomButton onClick={handleSubmit}>Добавить отрасль</CustomButton>
                    </div>
                </div>
                <div className={classes.adminToolsPanel}>
                    <div className={classes.adminToolBox}>
                        <h1>Удаление отрасли</h1>
                        <div className={classes.adminToolBox__controllers}>
                            <InputField
                                as="select"
                                value={formData.id || ''}
                                onChange={(e) => handleInputChange(e, 'id')}
                                error={errors.id}
                                errorMessage="Это обязательное поле. Заполните его!"
                            >
                                <option value="">Выберите индустрию для удаления</option>
                                {industryStore.industries.map((industry: IIndustry) => (
                                    <option key={industry.id} value={industry.id}>
                                        {industry.name}
                                    </option>
                                ))}
                            </InputField>
                        </div>
                        <CustomButton onClick={handleDelete}>Удалить отрасль</CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndustryCreator;
