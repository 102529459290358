import { useState, useContext, useEffect, useRef } from "react";

import Pagination from "../../UI/pagination/Pagination";
import ScrollToTopNavLink from "../../UI/ScrollToTopNavLink";
import CatalogFranchiseCard from "../../UI/cards/CatalogFranchiseCard";
import LoadingDots from "../../UI/loadingDots";
import FilterButton from "../../UI/buttons/FilterButton";
import NothingFound from "../NothingFoundPage/NothingFound";
import AllFiltersButton from "../../UI/buttons/AllFiltersButton";
import CustomButton from "../../UI/buttons/CustomButton";

import { useLayoutEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { IIndustry } from "../../models/IIndustry";
import { useLocation } from "react-router-dom";
import { FRANCHISE_CATALOG_ROUTE } from "../../utilits/consts";

import classes from "./FranchisesCatalog.module.scss";
import { Helmet } from "react-helmet";
import useResponsive from "../../hooks/useResponsive";
import FilterSwiper from "../../UI/swipers/FilterSwiper";

const FranchisesCatalog = () => {
     const location = useLocation();

     const isCatalogPage = location.pathname === FRANCHISE_CATALOG_ROUTE;
     const isHomePage = !isCatalogPage;
     
     const { franchiseStore, industryStore, typeOfActivityStore } = useContext(Context);

     const { isMobile, isTablet, isSmallDesktop, isMediumDesktop, isLargeDesktop } = useResponsive();

     useLayoutEffect(() => {
       if (isCatalogPage) {
               switch (true) {
                    case isMobile:
                    case isTablet:
                    case isSmallDesktop:
                         franchiseStore.setLimit(12);
                         break;
                    case isMediumDesktop:
                         franchiseStore.setLimit(20);
                         break;
                    case isLargeDesktop:
                         franchiseStore.setLimit(30);
                         break;
                    default:
                         franchiseStore.setLimit(12);
                         break;
               }
          } else {
               switch (true) {
                    case isMobile:
                    case isTablet:
                    case isSmallDesktop:
                         franchiseStore.setLimit(6);
                         break;
                    case isMediumDesktop:
                         franchiseStore.setLimit(8);
                         break;
                    case isLargeDesktop:
                         franchiseStore.setLimit(10);
                         break;
                    default:
                         franchiseStore.setLimit(6);
                         break;
               }
          }
     }, [isCatalogPage, isMobile, isTablet, isSmallDesktop, isMediumDesktop, isLargeDesktop, franchiseStore]);
      
     const totalPages = Math.ceil(franchiseStore.totalCount / (franchiseStore.limit || 1));

     const { filters } = franchiseStore;
     useEffect(() => {
          if (isCatalogPage) {
              franchiseStore.restoreFiltersFromStorage();
          }
      }, [isCatalogPage, franchiseStore]);
  
      useEffect(() => {
          if (isHomePage) {
              franchiseStore.fetchHomeData();
          }
      }, []);
     
     const isAllCategoriesSelected = filters.categories.length === 0 || filters.categories.includes(0);

     //#region Handlers
     const handleFilterChange = (category: number, checked: boolean) => {
          let newCategories = [...filters.categories];
          if (category === 0) {
              newCategories = checked ? [] : [0];
          } else {
              newCategories = checked ? [...newCategories, category] : newCategories.filter((c) => c !== category);
          }
          franchiseStore.setCategories(newCategories);
     };

     const handleBudgetChange = (budget: string) => {
          franchiseStore.setBudget(budget);
     };
      
     const handleSortChange = (sort: string) => {
          franchiseStore.setSort(sort);
     };
      
     const handleTypeChange = (types: number[]) => {
          franchiseStore.setTypes(types);
     };

     const handlePageChange = (page: number) => {
          franchiseStore.fetchCatalogPage(page);
     };
     //#endregion
     
     return (
          <>
               {isCatalogPage && (
                    <Helmet>
                         <title>Каталог франшиз</title>
                         <meta name="description" content="Выберите франшизы по категориям и фильтрам. Найдите идеальный бизнес для вас!" />
                    </Helmet>
               )}
               <div className={classes.catalog__container}>
                    <div className={classes.catalog__content}>
                         <div className={classes.catalog__header}>
                              <h1>Все франшизы</h1>
                              <nav className={classes.industrieFilters__wrapper}>
                                   {isMobile ? (
                                        <FilterSwiper
                                             filters={filters}
                                             showAllCategories={isAllCategoriesSelected}
                                             handleFilterChange={handleFilterChange}
                                        />
                                   ) : (
                                        <>
                                             <FilterButton
                                                  isActive={isAllCategoriesSelected}
                                                  onClick={() => handleFilterChange(0, true)}
                                             >
                                             Все категории
                                             </FilterButton>
          
                                             {industryStore.industries.map((industry: IIndustry) => (
                                                  <FilterButton
                                                       key={industry.id}
                                                       isActive={filters.categories.includes(industry.id)}
                                                       onClick={() =>
                                                            handleFilterChange(
                                                                 industry.id,
                                                                 !filters.categories.includes(industry.id)
                                                            )
                                                       }
                                                  >
                                                       {industry.name}
                                                  </FilterButton>
                                             ))}
                                        </>
                                   )}
                              </nav>
                              <nav className={classes.allFilters__button}>
                                   <AllFiltersButton
                                        onBudgetChange={handleBudgetChange}
                                        onSortChange={handleSortChange}
                                        onTypeChange={handleTypeChange}
                                        industries={industryStore.industries.map(industry => ({
                                             id: industry.id,
                                             name: industry.name,
                                             types: typeOfActivityStore.types.filter(type => type.industryId === industry.id)
                                        }))}
                                        selectedBudget={franchiseStore.filters.budget}
                                        selectedSort={franchiseStore.filters.sort}
                                        selectedTypes={franchiseStore.filters.types}
                                   />
                              </nav>
                         </div>
                         {franchiseStore.isLoading ? (
                              <section className={classes.catalog__franchisesContainer}>
                                   <LoadingDots />
                              </section>
                         ) : franchiseStore.franchises.length === 0 ? (
                              <NothingFound text="К сожалению, франшизы не найдены. Попробуйте изменить критерии поиска или обратитесь в службу поддержки." />
                         ) : (
                              <section className={classes.catalog__franchisesWrapper}>
                                   {franchiseStore.franchises.map((franchise) => (
                                        <CatalogFranchiseCard key={franchise.id} franchise={franchise} />
                                   ))}
                              </section>
                         )}

                         <div className={classes.catalog__controls}>
                              {!isCatalogPage ? (
                                   <ScrollToTopNavLink to={FRANCHISE_CATALOG_ROUTE}>
                                        <CustomButton bodyType="empty" style={{ display: "flex", margin: "0 auto" }}>
                                             Больше франшиз
                                        </CustomButton>
                                   </ScrollToTopNavLink>
                              ) : franchiseStore.franchises.length !== 0 &&(
                                   <Pagination
                                        currentPage={filters.page}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                   />
                              )}
                         </div>
                    </div>
               </div>
          </>
     );
};

export default observer(FranchisesCatalog);
