import classes from './Footer.module.scss';
import InstagramIcon from '../../assets/icons/instagram.svg'
import TikTokIcon from '../../assets/icons/tiktok.png'

import SubscribeInput from "../../UI/inputs/SubscribeInput";
import { CONTACTS_ROUTE, FAVORITES_ROUTE, FRANCHISE_CATALOG_ROUTE, HOME_ROUTE, NEWS_ROUTE, PROFILE_ROUTE } from "../../utilits/consts";
import ScrollToTopNavLink from "../../UI/ScrollToTopNavLink";

const Footer = () => {
    return (
        <footer>
            <div className={classes.footer__container}>
                <div className={classes.footer__content}>
                    <div className={classes.footer__body}>
                        <div className={classes.footer__column}>
                            <h3>Francheese</h3>
                            <p>
                                Ваш путеводитель в мире франшиз. Следите за нами в социальных сетях и оставайтесь на связи для актуальной информации, обновлений и поддержки вашего бизнеса.
                            </p>
                            <div className={classes.socialMedia}>
                                <a href="https://www.instagram.com/francheese.by">
                                    <img src={InstagramIcon} alt="Instagram" />
                                </a>
                                <a href="https://www.tiktok.com/@francheese.by?_t=ZN-8tFSwtOL3pu&_r=1">
                                    <img src={TikTokIcon} alt="TikTok" />
                                </a>
                            </div>
                        </div>
                        <div className={classes.footer__column}>
                            <h3>Мой аккаунт</h3>
                            <ul className={classes.footer__navigation}>
                                <li className={classes.buttons__box}>
                                    <ScrollToTopNavLink to={HOME_ROUTE}>
                                        Главная
                                    </ScrollToTopNavLink>
                                </li>
                                <li className={classes.buttons__box}>
                                    <ScrollToTopNavLink to={FRANCHISE_CATALOG_ROUTE}>
                                        Каталог
                                    </ScrollToTopNavLink>
                                </li>
                                <li className={classes.buttons__box}>
                                    <ScrollToTopNavLink to={NEWS_ROUTE}>
                                        Новости
                                    </ScrollToTopNavLink>
                                </li>
                                <li className={classes.buttons__box}>
                                    <ScrollToTopNavLink to={PROFILE_ROUTE}>
                                        Профиль
                                    </ScrollToTopNavLink>
                                </li>
                                <li className={classes.buttons__box}>
                                    <ScrollToTopNavLink to={FAVORITES_ROUTE}>
                                        Избранное
                                    </ScrollToTopNavLink>
                                </li>
                                <li className={classes.buttons__box}>
                                    <ScrollToTopNavLink to={CONTACTS_ROUTE}>
                                        Контакты
                                    </ScrollToTopNavLink>
                                </li>
                            </ul>
                        </div>
                        <div className={classes.footer__column}>
                            <h3>Оставайтесь в курсе событий</h3>
                            <p>
                                Оставайтесь в курсе последних событий и новостей. Подпишитесь на нашу рассылку, чтобы получать самую свежую информацию на свою почту.
                            </p>
                            <SubscribeInput />
                        </div>
                    </div>
                    <p className={classes.footer__copyright}>Copyright © 2024 aucuo</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;