import { useEffect, useState, useContext } from "react";

import FranchiseModal from "./FranchiseModal/FranchiseModal";
import FranchisesYouMayLike from "./FranchisesYouMayLike/FranchisesYouMayLike";
import GallerySwiper from "../../UI/swipers/GallerySwiper";
import CustomButton from "../../UI/buttons/CustomButton";

import { useNavigate, useParams } from 'react-router-dom';
import { Context } from "../../index";
import { IFranchise } from "../../models/IFranchise";
import { STATIC_URL } from "../../http";
import { formatPrice } from "../../utilits/utilities";
import {NOTHING_FOUND_ROUTE } from "../../utilits/consts";
import { EditorState, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import classes from './FranchisePage.module.scss';
import Skeleton from "../../UI/skeletons/Skeleton";
import { Helmet } from "react-helmet";
import { IDescriptionBlock } from "../../models/IDescriptionBlock";

const FranchisePage = () => {
     const { id } = useParams<{ id: string }>();
     const { franchiseStore, franchiseDescriptionStore, industryStore, typeOfActivityStore } = useContext(Context);
     const [franchise, setFranchise] = useState<IFranchise | undefined>(undefined);
     const [descriptionsBlocks, setDescriptionBloks] = useState<IDescriptionBlock[] | undefined>(undefined);
     const [loading, setLoading] = useState<boolean>(true);
     const navigate = useNavigate();
     
     const [isModalOpen, setIsModalOpen] = useState(false);

     useEffect(() => {
          const fetchFranchise = async () => {
               setLoading(true);
               try {
                    const foundFranchise = await franchiseStore.fetchOneFranchise(parseInt(id || ""));
                    if (!foundFranchise) {
                         navigate(NOTHING_FOUND_ROUTE);
                         return;
                    }
                    await franchiseStore.fetchFranchisesByIndustry(foundFranchise.id, foundFranchise.industryId, 3);
                    setFranchise(foundFranchise);

                    const blocks = await franchiseDescriptionStore.fetchFranchiseDescriptions(foundFranchise.id);
                    setDescriptionBloks(blocks);
               } catch (e) {
                    navigate(NOTHING_FOUND_ROUTE);
               } finally {
                    setLoading(false);
               }
          };
          fetchFranchise();
     }, [id, franchiseStore]);
     
     if (loading || !franchise) {
          return  (
               <div className={classes.franchise__container}>
                    <div className={classes.franchise__wrapper}>
                         <div className={classes.franchise__characteristic}>
                              <Skeleton className={classes.franchise__image}/>
                              <h1>
                                   <Skeleton width={"50%"} height={"3rem"}/>
                              </h1>
                              <div className={classes.franchise__infoTable}>
                                   <Skeleton height={"25vw"} borderRadius={4}/>
                              </div>
                              <div className={classes.franchise__button}>
                                   <Skeleton className={classes.franchise__button} width={"20%"} height={"3rem"} borderRadius={60}/> 
                              </div>
                         </div>
                    </div>
               </div>
          )
     }

     const handleOpenModal = () => {
          setIsModalOpen(true);
     };
   
     const handleCloseModal = () => {
          setIsModalOpen(false);
     }

     return (
          <>
              <Helmet>
                    <title>{franchise.name ? `${franchise.name} | Каталог франшиз` : "Каталог франшиз"}</title>
                    <meta 
                         name="description" 
                         content={
                              franchise.investments 
                              ? `Узнайте больше о франшизе ${franchise.name}. Инвестиции начинаются от ${formatPrice(franchise.investments)} BYN. Отличный шанс начать бизнес!` 
                              : `Франшиза ${franchise.name || "Без названия"} — подробности, инвестиции и условия.`
                         } 
                    />
                    <meta 
                         property="og:title" 
                         content={franchise.name ? `${franchise.name} | Каталог франшиз` : "Каталог франшиз"} 
                    />
                    <meta 
                         property="og:description" 
                         content={
                              franchise.investments 
                              ? `Начните свой бизнес с франшизой ${franchise.name}. Инвестиции от ${formatPrice(franchise.investments)} BYN.` 
                              : `Подробная информация о франшизе ${franchise.name || "Без названия"}.`
                         } 
                    />
                    <meta 
                         property="og:image" 
                         content={franchise.faceImage ? `${STATIC_URL}/franchises/${franchise.faceImage}` : "/default-image.jpg"} 
                    />
               </Helmet>

               <div className={classes.franchise__container}>
                    <div className={classes.franchise__wrapper}>
                         <div className={classes.franchise__characteristic}>
                              <img className={classes.franchise__image} src={`${STATIC_URL}/franchises/${franchise.faceImage}`} alt="" />
                              <h1>{franchise.name}</h1>
                              <div className={classes.franchise__infoTable}>
                                   <div className={classes.infoTable__row}>
                                        <b>Отрасль:</b>
                                        <p>{industryStore.getName(franchise.industryId)}</p>
                                   </div>
                                   <div className={classes.infoTable__row}>
                                        <b>Вид деятельности:</b>
                                        <p>{typeOfActivityStore.getName(franchise.typeOfActivityId)}</p>
                                   </div>
                                   <div className={classes.infoTable__row}>
                                        <b>Бренд:</b>
                                        <p>{franchise.brand}</p>
                                   </div>
                                   <div className={classes.infoTable__row}>
                                        <b>Пашуальный взнос:</b>
                                        <p>{formatPrice(franchise.advertisingFee)} BYN</p>
                                   </div>
                                   <div className={classes.infoTable__row}>
                                        <b>Инвестиции:</b>
                                        <p>от {formatPrice(franchise.investments)} BYN</p>
                                   </div>
                                   <div className={classes.infoTable__row}>
                                        <b>Окупаемость:</b>
                                        <p>от {franchise.paybackTime} месяцев</p>
                                   </div>
                                   <div className={classes.infoTable__row}>
                                        <b>Роялти:</b>
                                        <p>от {franchise.royalty}%</p>
                                   </div>
                                   <div className={classes.infoTable__row}>
                                        <b>Год запуска компании:</b>
                                        <p>{franchise.companyStartYear || new Date().getFullYear()}</p>
                                   </div>
                                   <div className={classes.infoTable__row}>
                                        <b>Год запуска франшизы:</b>
                                        <p>{franchise.franchiseStartYear || new Date().getFullYear()}</p>
                                   </div>
                                   <div className={classes.infoTable__row}>
                                        <b>Кол-во собственных точек:</b>
                                        <p>{franchise.ownPoints}</p>
                                   </div>
                                   <div className={classes.infoTable__row}>
                                        <b>Кол-во франчайзинговых точек:</b>
                                        <p>{franchise.franchisePoints}</p>
                                   </div>
                              </div>  
                              <div className={classes.franchise__button}>
                                   <CustomButton onClick={handleOpenModal}>
                                        Оставить заявку
                                   </CustomButton>
                              </div>  
                         </div>

                         <div className={classes.franchise__descriptionBlocks}>
                              {descriptionsBlocks?.map((block, _) => {
                                   const contentState = block.description ? convertFromRaw(JSON.parse(block.description)) : null;
                                   const editorState = contentState ? EditorState.createWithContent(contentState) : null;
                                   const html = editorState ? stateToHTML(editorState.getCurrentContent()) : '';
                                   
                                   return (
                                        <>
                                             <div className={classes.franchisor_description}>
                                                  <div dangerouslySetInnerHTML={{ __html: html }} className={classes.description__text}></div>
                                             </div>
                                             {Array.isArray(block.imageGallery) && block.imageGallery.length > 0 && (
                                                  <div className={classes.franchise__gallery}>
                                                  <GallerySwiper
                                                       images={block.imageGallery.map(
                                                            image => `${STATIC_URL}/franchises/${image}`
                                                       )}
                                                  />
                                                  </div>
                                             )}
                                        </>
                                   );
                              })}
                         </div>
                    </div>
               </div>
               <FranchiseModal isOpen={isModalOpen} onClose={handleCloseModal} franchise={franchise}/>
               <FranchisesYouMayLike franchiseId={franchise.id} industryId={franchise.industryId} />
          </>
     );
};

export default FranchisePage;
