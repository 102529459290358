import { useState, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { Context } from '../..';
import { ITypeOfActivity } from '../../models/ITypeOfActivity';
import { useNotification } from '../../hooks/useNotification';

import CustomButton from '../../UI/buttons/CustomButton';
import InputField from '../../UI/inputs/InputField/InputField';

import classes from './AdminPageForms.module.scss';

const TypeCreator = () => {
     const { typeOfActivityStore, industryStore } = useContext(Context);
     const { notify, showLoading, hideLoading } = useNotification();

     const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
     const [formData, setFormData] = useState<ITypeOfActivity>({
          id: 0,
          name: '',
          industryId: 0
     });

     const [selectedTypeId, setSelectedTypeId] = useState<number | null>(null);
     const [selectedIndustryId, setSelectedIndustryId] = useState<number | null>(null);

     const validationSchema = Yup.object({
          name: Yup.string().required('Название подотрасли обязательно').max(200, 'Максимум 200 символов'),
          industryId: Yup.number().required('Выберите отрасль для подотрасли'),
     });

     const handleInputChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
          field: keyof ITypeOfActivity
     ) => {
          const value = e.target.value;
          setFormData({
               ...formData,
               [field]: value,
          });

          setErrors({ ...errors, [field]: false });
     };

     const handleSubmit = async () => {
          try {
               await validationSchema.validate(formData, { abortEarly: false });
              
               showLoading('Создаем новый тип активности');
               await typeOfActivityStore.createType(formData);
              
               hideLoading();
              
               await industryStore.fetchAllSubIndustries();
               notify(`Тип активности ${formData.name} успешно создан!`);

               setFormData({ id: 0, name: '', industryId: 0 });
               setErrors({});
          } catch (error) {
               if (error instanceof Yup.ValidationError) {
                    const newErrors: { [key: string]: boolean } = {};
                    error.inner.forEach((err) => {
                         if (err.path) {
                         newErrors[err.path] = true;
                         }
                    });
                    setErrors(newErrors);
               }
               console.error("Не удалось создать тип:", error);
          }
     };

     const handleDelete = async () => {
          if (selectedTypeId !== null) {
               try {
                    showLoading("Удаляем тип активности");
                    await typeOfActivityStore.deleteType(selectedTypeId);
                    
                    hideLoading();
                    
                    await industryStore.fetchAllSubIndustries();
                    notify(`Тип активности успешно удален!`);

                    setFormData({ id: 0, name: '', industryId: 0 });
                    setErrors({});
                    setSelectedTypeId(null);
               } catch (error) {
                    console.error("Не удалось удалить тип:", error);
               }
          } else {
               console.error("Не выбран тип для удаления");
          }
     };

     return (
          <div className={classes.adminDashboard__container}>
               <div className={classes.adminDashboard__content}>
                    <div className={classes.adminToolsPanel}>
                         <div className={classes.adminToolBox}>
                              <h1>Создание подотраслей</h1>
                              <div className={classes.adminToolBox__controllers}>
                                   <InputField
                                        as="select"
                                        value={formData.industryId || ''}
                                        onChange={(e) => handleInputChange(e, 'industryId')}
                                        error={errors.industryId}
                                        errorMessage="Это обязательное поле. Заполните его!"
                                   >
                                        <option value="">Выберите отрасль</option>
                                        {industryStore.industries.map((industry) => (
                                             <option key={industry.id} value={industry.id}>
                                                  {industry.name}
                                             </option>
                                        ))}
                                   </InputField>

                                   <InputField
                                        as="input"
                                        maxLength={200}
                                        placeholder="Название новой подотрасли"
                                        value={formData.name}
                                        onChange={(e) => handleInputChange(e, 'name')}
                                        error={errors.name}
                                        errorMessage="Это обязательное поле. Заполните его!"
                                   />
                              </div>
                              <CustomButton onClick={handleSubmit} >
                                   Создать тип активности
                              </CustomButton>
                         </div>
                    </div>
                    <div className={classes.adminToolsPanel}>
                         <div className={classes.adminToolBox}>
                              <h1>Удаление подотраслей</h1>
                              <div className={classes.adminToolBox__controllers}>
                                   <InputField
                                        as="select"
                                        value={selectedIndustryId || ''}
                                        onChange={(e) => setSelectedIndustryId(Number(e.target.value))}
                                        error={errors.industryId}
                                        errorMessage="Выберите отрасль для отображения подотрослей"
                                   >
                                        <option value="">Выберите отрасль</option>
                                        {industryStore.industries.map((industry) => (
                                             <option key={industry.id} value={industry.id}>
                                                  {industry.name}
                                             </option>
                                        ))}
                                   </InputField>

                                   <InputField
                                        as="select"
                                        value={selectedTypeId || ''}
                                        onChange={(e) => setSelectedTypeId(Number(e.target.value))}
                                        error={errors.industryId}
                                        errorMessage="Выберите отрасль для отображения подотрослей"
                                        >
                                        <option value="">Выберите подотрасль</option>
                                        {selectedIndustryId !== null && industryStore.subIndustries[selectedIndustryId] ? (
                                             industryStore.subIndustries[selectedIndustryId].map((type: ITypeOfActivity) => (
                                                  <option key={type.id} value={type.id}>
                                                       {type.name}
                                                  </option>
                                             ))
                                        ) : (
                                             <option disabled>Подотрасли не найдены</option>
                                        )}
                                   </InputField>
                              </div>
                              <CustomButton onClick={handleDelete} >
                                   Удалить тип подотрасли
                              </CustomButton>
                         </div>
                    </div>
               </div>
          </div>
     );
};

export default TypeCreator;
