import { makeAutoObservable, runInAction, toJS } from "mobx";
import { IFranchise } from "../models/IFranchise";
import FranchiseService from "../service/FranchiseService";
import { IDescriptionBlock } from "../models/IDescriptionBlock";

type Filter = {
     categories: number[];
     types: number[];
     budget: string;
     sort: string;
     page: number;
}

export default class FranchiseStore {
     private _franchises: IFranchise[] = [];
     private _recommendedFranchises: IFranchise[] = [];

     private _totalCount: number = 0;
     private _limit: number | undefined = 10;
     
     private _loading: boolean = false;

     private _filters: Filter = {
          categories: [],
          types: [],
          budget: "Все",
          sort: "По популярности",
          page: 1
     };

     constructor() {
          makeAutoObservable(this);
     }
     
     setCategories(categories: number[]) {
          this._filters.categories = categories;
          this.saveFiltersToStorage();
          this.fetchCatalogPage(1);
     }
     
     setTypes(types: number[]) {
          this._filters.types = types;
          this.saveFiltersToStorage();
          this.fetchCatalogPage(1);
     }
     
     setBudget(budget: string) {
          this._filters.budget = budget;
          this.saveFiltersToStorage();
          this.fetchCatalogPage(1);
     }
     
     setSort(sort: string) {
          this._filters.sort = sort;
          this.saveFiltersToStorage();
          this.fetchCatalogPage(1);
     }

     setFilters(newfilter: Filter) {
          this._filters = newfilter;
          this.saveFiltersToStorage();
          this.fetchCatalogPage(1);
     }

     saveFiltersToStorage() {
          sessionStorage.setItem('filters', JSON.stringify(this._filters));
     }

     public restoreFiltersFromStorage() {
          const savedFilters = sessionStorage.getItem('filters');
          if (savedFilters) {
              const parsedFilters = JSON.parse(savedFilters);
              runInAction(() => {
                  this._filters = {
                      ...this._filters,
                      categories: parsedFilters.categories || [],
                      types: parsedFilters.types || [],
                      budget: parsedFilters.budget || "Все",
                      sort: parsedFilters.sort || "По популярности",
                      page: parsedFilters.page || 1,
                  };
              });
              this.fetchCatalogPage(this._filters.page);
          } else {
              this.fetchPage(1);
          }
     }
  
     async fetchHomeData() {
          this.setLoading(true);
          try {
               this.resetFilters();
               const { franchises, totalCount } = await FranchiseService.fetchPage(
                    [],
                    [],
                    1,
                    this._limit,
                    "Все",
                    "По популярности"
               );
               runInAction(() => {
                    this._franchises = franchises;
                    this._totalCount = totalCount;
               });
          } catch (error) {
               console.error("Не удалось получить данные для главной страницы:", error);
          } finally {
               this.setLoading(false);
          }
     }

     private resetFilters() {
          this._filters = {
               categories: [],
               types: [],
               budget: "Все",
               sort: "По популярности",
               page: 1
          };
          sessionStorage.removeItem('filters');
     }

     fetchCatalogPage(page: number) {
          this._filters.page = page;
          this.saveFiltersToStorage(); 
          this.fetchPage(page);
      }

     async updateFranchiseOrder(selectedFranchises: number[]) {
          try {
              await FranchiseService.updateFranchiseOrder(selectedFranchises);
          } catch (error) {
              console.error("Не удалось обновить порядок франшизы:", error);
          }
     }

     async fetchPage(page: number, limit: number | undefined = this._limit) {
          this.setLoading(true);
          try {
               const { franchises, totalCount } = await FranchiseService.fetchPage(
                    this._filters.categories,
                    this._filters.types,
                    page, 
                    limit,
                    this._filters.budget,
                    this._filters.sort,
               );
               runInAction(() => {
                    this._franchises = franchises;
                    this._totalCount = totalCount;
                    this._limit = limit;
               });
          } catch (error) {
              console.error("Не удалось получить данные о франшизах:", error);
          } finally {
              this.setLoading(false);
          }
     }

     async fetchAllFranchises() {
          this.setLoading(true);
          try {
               const franchises = await FranchiseService.fetchAllFranchises();
               this._franchises = franchises;
          } catch (error) {
              console.error("Не удалось получить данные о франшизах:", error);
          } finally {
              this.setLoading(false);
          }
     }
     
     setLimit(limit: number | undefined) {
          this._limit = limit;
     }

     async fetchInitialData() {
          this.resetFilters();
          this.fetchPage(1);
     }
  
     async fetchRecommendedFranchises() {
          try {
               const recommendedFranchises = await FranchiseService.fetchRecommendedFranchises();
               this._recommendedFranchises = recommendedFranchises; 
          } catch (error) {
               console.error("Не удалось получить рекомендованные франшизы:", error);
               return { franchises: [] };
          }
     }

     async setRecommendedFranchises(newRecommendedFranchises: number[]) {
          try {
               const recommendedFranchises = await FranchiseService.setRecommendedFranchises(newRecommendedFranchises);
               this._recommendedFranchises = recommendedFranchises; 
          } catch (error) {
               console.error("Не удалось получить рекомендованные франшизы:", error);
               return { franchises: [] };
          }
     }
     
     async fetchOneFranchise(id: number): Promise<IFranchise> {
          this.setLoading(true);
          try {
               const franchise = await FranchiseService.fetchOneFranchise(id);
               return franchise;
          } catch (error) {
               console.error("Не удалось получить франшизу:", error);
               throw error;
          }
          finally {
               this.setLoading(false);
          }
     }

     async fetchFranchisesByIndustry(id:number, industryId:number, limit: number) {
          this.setLoading(true);
          try {
               return await FranchiseService.fetchFranchisesByIndustry(id, industryId, limit);
          } catch (error) {
               console.error("Не удалось получить франшизу:", error);
               throw error;
          } finally {
               this.setLoading(false);
          }
     }

     async fetchFranchiseByReleaseId(releaseId: number): Promise<IFranchise> {
          try {
               const franchise = await FranchiseService.fetchFranchiseByReleaseId(releaseId);
               return franchise;
          } catch (error) {
               console.error("Не удалось получить франшизу:", error);
               throw error;
          }
     }

     async createFranchise ( 
          form: {
               name: string,
               royalty: string,
               advertisingFee: string,
               investments: string,
               ownPoints: string,
               franchisePoints: string,
               paybackTime: string,
               industryId: string,
               typeOfActivityId: string,
               brand: string,
               faceImage: File,
               companyStartYear: string;
               franchiseStartYear: string;
               descriptionBlocks: IDescriptionBlock[]
          },
     ) {
          try {
               await FranchiseService.createFranchise(form);
          } catch (error) {
               console.error("Не удалось создать франшизу:", error);
          }
     }
     
     async updateFranchise (
          id: string,
          form : {
               name: string,
               royalty: string,
               advertisingFee: string,
               investments: string,
               ownPoints: string,
               franchisePoints: string,
               paybackTime: string,
               industryId: string,
               typeOfActivityId: string,
               brand: string,
               faceImage: File,
               companyStartYear: string;
               franchiseStartYear: string;
               descriptionBlocks: IDescriptionBlock[]
          }
     ) {
          try {
               await FranchiseService.updateFranchise(id, form);
          } catch (error) {
               console.error("Не удалось обновить франшизу:", error);
          }
     }

     async deleteOneFranchiseById(id: number): Promise<IFranchise> {
          try {
               const message = await FranchiseService.deleteOneFranchise(id);
               return message;
          } catch (error) {
               throw error;
          }
     }

     setLoading(bool: boolean) {
          this._loading = bool;
     }
     
     get filters() {
          return this._filters;
     }

     get franchises(): IFranchise[] {
          return toJS(this._franchises);
     }

     get totalCount() {
          return this._totalCount
     }
     
     get recommendedFranchises() : IFranchise[] {
          return toJS(this._recommendedFranchises)
     }

     get limit() {
          return this._limit
     }

     get isLoading() {
          return this._loading;
     }
}