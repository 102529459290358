import { FC, useState, useEffect, useRef } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, DraftHandleValue } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { ContentState } from 'draft-js';
import classes from './DraftEditor.module.scss';

interface DraftEditorProps {
    editorState: EditorState;
    onEditorStateChange: (state: EditorState) => void;
    onContentChange?: (content: string) => void;
    error?: boolean;
    errorMessage?: string;
    maxLength: number;
}

const DraftEditor: FC<DraftEditorProps> = ({ editorState, onEditorStateChange, onContentChange, error, errorMessage, maxLength }) => {
    const editorRef = useRef<Editor | null>(null);
    const [charCount, setCharCount] = useState<number>(editorState.getCurrentContent().getPlainText().length);

    useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const contentAsText = contentState.getPlainText();
        setCharCount(contentAsText.length);
    }, [editorState]);

    const handleKeyCommand = (command: string, state: EditorState): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(state, command);
        if (newState) {
            onEditorStateChange(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const handlePastedText = (text: string, html: string | undefined, state: EditorState): DraftHandleValue => {
        if (text) {
            const newContentState = ContentState.createFromText(text);
            const newEditorState = EditorState.push(state, newContentState, 'insert-fragment');
            onEditorStateChange(newEditorState);
            return 'handled';
        }
        return 'not-handled';
    };

    const onEditorChange = (state: EditorState) => {
        const contentState = state.getCurrentContent();
        let contentAsText = contentState.getPlainText();
        
        if (contentAsText.length > maxLength) {
            contentAsText = contentAsText.substring(0, maxLength);
            const truncatedContentState = ContentState.createFromText(contentAsText);
            state = EditorState.push(state, truncatedContentState, 'insert-fragment');
        }
    
        const newEditorState = EditorState.set(state, {
            currentContent: state.getCurrentContent(),
            selection: state.getSelection(),
        });
    
        onEditorStateChange(newEditorState);
        setCharCount(contentAsText.length);
    
        if (onContentChange) {
            const contentJSON = JSON.stringify(convertToRaw(state.getCurrentContent()));
            onContentChange(contentJSON);
        }
    };

    const toggleInlineStyle = (style: string) => {
        onEditorStateChange(RichUtils.toggleInlineStyle(editorState, style));
    };

    const toggleBlockType = (blockType: string) => {
        if (blockType === 'unordered-list-item' || blockType === 'ordered-list-item') {
            onEditorStateChange(RichUtils.toggleBlockType(editorState, blockType));
        }
    };

    const isStyleActive = (style: string): boolean => {
        return editorState.getCurrentInlineStyle().has(style);
    };

    const isBlockActive = (blockType: string): boolean => {
        const currentBlock = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey());
        return currentBlock.getType() === blockType;
    };


    return (
        <div className={`${classes.draftEditor} ${error ? classes.error : ''}`}>
            <div className={classes.toolbar} role="toolbar" aria-label="Форматирование текста">
                <button
                    onClick={() => toggleInlineStyle('BOLD')}
                    title="Сделать текст жирным"
                    aria-label="Жирный"
                    className={isStyleActive('BOLD') ? classes.active : ''}
                >
                    Жирный
                </button>
                <button
                    onClick={() => toggleInlineStyle('ITALIC')}
                    title="Сделать текст курсивом"
                    aria-label="Курсив"
                    className={isStyleActive('ITALIC') ? classes.active : ''}
                >
                    Курсив
                </button>
                <button
                    onClick={() => toggleInlineStyle('UNDERLINE')}
                    title="Подчеркнуть текст"
                    aria-label="Подчёркивание"
                    className={isStyleActive('UNDERLINE') ? classes.active : ''}
                >
                    Подчёркивание
                </button>
                <button
                    onClick={() => toggleBlockType('unordered-list-item')}
                    title="Добавить маркированный список"
                    aria-label="Маркированный список"
                    className={isBlockActive('unordered-list-item') ? classes.active : ''}
                >
                    Маркер
                </button>
                <button
                    onClick={() => toggleBlockType('ordered-list-item')}
                    title="Добавить нумерованный список"
                    aria-label="Нумерованный список"
                    className={isBlockActive('ordered-list-item') ? classes.active : ''}
                >
                    Нумерация
                </button>
            </div>
            <div className={classes.editor} onClick={() => editorRef.current?.focus()}>
                <Editor
                    ref={(el) => (editorRef.current = el)}
                    placeholder="Введите свой текст"
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onChange={onEditorChange}
                    handlePastedText={handlePastedText}
                    aria-describedby={error ? "error-message" : undefined}
                    tabIndex={0}
                />
            </div>
            <span className={classes.charCount} aria-live="polite">
                {charCount}/{maxLength}
            </span>
            {error && errorMessage && (
                <div className={classes.errorMessage} role="alert">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default DraftEditor;