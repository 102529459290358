import React, { useState, useRef, useEffect } from "react";
import classes from './AllFiltersButton.module.scss';
import AllFiltersTriangle from '../../assets/icons/allFiltersTriangle.svg';
import FilterButton from "./FilterButton";
import IndustryDropdownButtons from "./IndustryDropdownButtons";
import { observer } from "mobx-react-lite";

interface FilterOption {
    id: number;
    name: string;
}

interface Industry {
    id: number;
    name: string;
    types: FilterOption[];
}

interface AllFiltersButtonProps {
    onBudgetChange: (budget: string) => void;
    onSortChange: (sort: string) => void;
    onTypeChange: (types: number[]) => void;
    industries: Industry[];
    selectedBudget: string;
    selectedSort: string;
    selectedTypes: number[];
}

const AllFiltersButton: React.FC<AllFiltersButtonProps> = ({
    onBudgetChange,
    onSortChange,
    onTypeChange,
    industries,
    selectedBudget: initialBudget,
    selectedSort: initialSort,
    selectedTypes: initialTypes,
}) => {
    const [isFiltersVisible, setFiltersVisible] = useState(false);
    const [selectedBudget, setSelectedBudget] = useState(initialBudget);
    const [selectedSort, setSelectedSort] = useState(initialSort);
    const [selectedTypes, setSelectedTypes] = useState<number[]>(initialTypes);
    const [expandedIndustries, setExpandedIndustries] = useState<number[]>([]);
    const [activeFiltersCount, setActiveFiltersCount] = useState(0);
    const filtersRef = useRef<HTMLDivElement>(null);

    const toggleFiltersVisibility = () => {
        setFiltersVisible(!isFiltersVisible);
    };

    //#region Hnadlers
    const handleBudgetChange = (budget: string) => {
        const newBudget = selectedBudget === budget ? "Все" : budget;
        setSelectedBudget(newBudget);
        onBudgetChange(newBudget);
    };

    const handleSortChange = (sort: string) => {
        const newSort = selectedSort === sort ? "По популярности" : sort;
        setSelectedSort(newSort);
        onSortChange(newSort);
    };

    const handleTypeChange = (type: number) => {
        setSelectedTypes(prev => {
            const updatedTypes = prev.includes(type) 
                ? prev.filter(t => t !== type) 
                : [...prev, type];
            onTypeChange(updatedTypes);
            return updatedTypes;
        });
    };
    //#endregion
    
    const toggleIndustryDropdown = (industryId: number) => {
        setExpandedIndustries(prev => 
            prev.includes(industryId) 
                ? prev.filter(id => id !== industryId) 
                : [...prev, industryId]
        );
    };

    //#region ClickOtside
    const handleClickOutside = (event: MouseEvent) => {
        if (filtersRef.current && !filtersRef.current.contains(event.target as Node)) {
            setFiltersVisible(false);
        }
    };

    useEffect(() => {
        if (isFiltersVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isFiltersVisible]);
    //#endregion
    
    useEffect(() => {
        setSelectedBudget(initialBudget);
    }, [initialBudget]);

    useEffect(() => {
        setSelectedSort(initialSort);
    }, [initialSort]);

    useEffect(() => {
        setSelectedTypes(initialTypes);
    }, [initialTypes]);
    
    useEffect(() => {
        const count = 
            (selectedBudget !== "Все" ? 1 : 0) +
            (selectedSort !== "По популярности" ? 1 : 0) +
            selectedTypes.length;
    
        setActiveFiltersCount(count);
    }, [selectedBudget, selectedSort, selectedTypes]);

    return (
        <div className={classes.allFilters__container} ref={filtersRef}>
            <button className={classes.allFilters__button} onClick={toggleFiltersVisibility}>
                <img src={AllFiltersTriangle} alt="Все фильтры" />
                Все фильтры
                {activeFiltersCount > 0 && (
                    <span>{activeFiltersCount}</span>
                )}
            </button>

            {isFiltersVisible && (
                <div className={classes.filters__config}>
                    <div className={classes.config__wrapper}>
                        <div className={classes.filters__box}>
                            <p>Сортировка</p>
                            <div className={classes.filters__buttonsBox}>
                                {['По популярности', 'Сначала дешевые', 'Сначала дорогие', 'По сроку окупаемости'].map((sort) => (
                                    <FilterButton
                                        key={sort}
                                        isActive={selectedSort === sort}
                                        onClick={() => handleSortChange(sort)}
                                    >
                                        {sort}
                                    </FilterButton>
                                ))}
                            </div>
                        </div>
                        <div className={classes.filters__box}>
                            <p>Бюджет</p>
                            <div className={classes.filters__buttonsBox}>
                                {['Все', 'До 5 тыс', 'До 10 тыс', 'До 15 тыс', 'До 25 тыс', 'До 50 тыс', 'До 100 тыс', 'Более 100 тыс'].map((budget) => (
                                    <FilterButton
                                        key={budget}
                                        isActive={selectedBudget === budget}
                                        onClick={() => handleBudgetChange(budget)}
                                    >
                                        {budget}
                                    </FilterButton>
                                ))}
                            </div>
                        </div>
                        <div className={classes.filters__box}>
                            <p>Тип активности</p>
                            <div className={classes.filters__dropdownButtons}>
                                {industries.map((industry) => (
                                    <IndustryDropdownButtons
                                        key={industry.id}
                                        industry={industry}
                                        selectedTypes={selectedTypes}
                                        onTypeChange={handleTypeChange}
                                        expandedIndustry={expandedIndustries}
                                        toggleIndustryDropdown={toggleIndustryDropdown}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default observer(AllFiltersButton);